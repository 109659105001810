export const configcode = {
  debug: false, //enable console.log
  // ------------- Development Settings ----------------
  api: "stg",
  baseSelfUrl: "https://frontend-stg.saijai-crm.com",
  
  //base saijai commission api url
  baseUrl: "https://backend-stg.saijai-crm.com",

  //base saijai pos web url
  basePOSUrl: "https://saijaipos-stg.saijai-crm.com",

  //base saijai api blob url
  bzbsBlobUrl: "https://stgstoragebuzzebees.blob.core.windows.net",
  basePublic: "https://public-stg.saijai-crm.com",
  themeVersion: 3,
};